import { useState } from "react";
import styles from "./Project.module.css";


const Project = ({data}) => {

    const navigateToGit = () => {
        window.open(data.github);
    }

    const navigateToSite = () => {
        window.open(data.site);
    }

    return (
    <div className={styles.conteiner}>
        <div className={styles.infsConteiner}>
        <img src={data.image}/>
        <h1>{data.tittle}</h1>
        <p>{data.description}</p>
        </div>
        <div className={styles.bntConteiner}>
            <button className={styles.bntGit} onClick={navigateToGit}>GitHub</button>
            <button className={styles.bntSite} onClick={navigateToSite}>Site</button>
        </div>
    </div>
  )
}

export default Project