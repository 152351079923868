import styles from "./About.module.css";
import felipe from "../Img/felipe.jpeg"
import { useRef } from "react";

const About = () => {

  const handleScrollProjects = (event) => {
    event.preventDefault();
    const element = document.querySelector("#projects");
    element.scrollIntoView({ behavior: "smooth" });
  }

  const navigateToLinkedin = () => {
    window.open("https://www.linkedin.com/in/felipecalixto03/");
  }  

  return (
    <div className={styles.conteiner} id="about">
        <div className={styles.myPhotoConteiner}>
          <div>
            <img src={felipe}/>
          </div>
        </div>
        <div className={styles.aboutConteiner}>
            <h1>Sobre</h1>  
            <p>Olá! Meu nome é Felipe Calixto e sou um desenvolvedor full stack. 
              Com experiência em desenvolvimento web e mobile, tenho habilidades tanto no front-end quanto no back-end. 
              Minha paixão por tecnologia e programação começou cedo e desde então venho sempre tentando apreender cada vez mais.</p>
            <div>
            <button onClick={handleScrollProjects}>Projetos</button>
            <button onClick={navigateToLinkedin}>Linkedin</button>
            </div>
        </div>
    </div>
  )
}

export default About