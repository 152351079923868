import styles from "./Projects.module.css";

import  Carousel  from  "react-multi-carousel" ; 
import  "react-multi-carousel/lib/styles.css" ; 
import Project from "./Project";
import { useEffect, useState } from "react";
import axios from "axios";

const Projects = () => {

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const [data, setData] = useState([]);

      useEffect(() => {
          axios.get("AllProjects.json")
          .then((res) => {
              setData(res.data);
          })
      },[])  

  return (
    <div className={styles.conteiner} id="projects">
        <h1 className={styles.tittle}>Projetos</h1>
        <Carousel responsive={responsive} className={styles.carousel}>
            {data && data.map((data) => (
                <Project data={data} key={data.id}/>
            ))}
        </Carousel>
    </div>
  )
}

export default Projects