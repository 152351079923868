import styles from "./Introduction.module.css";

const Introduction = () => {

  const handleScrollAbout = (event) => {
    event.preventDefault();
    const element = document.querySelector("#about");
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className={styles.conteiner}>
        <div className={styles.infsConteiner}>
            <h1 className="animation">Felipe Calixto</h1>
            <h3>Dev Full-Stack</h3>
            <button onClick={handleScrollAbout}>Sobre</button>
        </div>
    </div>
  )
}

export default Introduction