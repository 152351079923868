import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={styles.webmemes}>
            <h3>Desenvolvido por Felipe Calixto</h3>
            <p>Felipe Calixto &copy; 2023</p>
        </div>
        <div className={styles.webmemes}>
            <h3>Contato</h3>
            <p>Email: felipecalixtoribas@gmail.com</p>
            <p>Linkedin: felipecalixto03</p>
        </div>
        <div className={styles.webmemes}>
          <h3>Sobre</h3>
          <p>Olá! Meu nome é Felipe Calixto e sou um desenvolvedor full stack. Com experiência em desenvolvimento web e mobile, tenho habilidades tanto no front-end quanto no back-end. Minha paixão por tecnologia e programação começou cedo e desde então venho sempre tentando apreender cada vez mais.</p>
        </div>
    </footer>
  )
}

export default Footer