import styles from "./Contact.module.css";

import { GiCancel } from 'react-icons/gi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { AiFillGithub } from 'react-icons/ai';
import { CgMail } from 'react-icons/cg';

const Contact = ({ prop }) => {

  const navigateToEmail = () => {
    window.open("http://mail.google.com/mail?hl=pt-BR");
  }

  const navigateToLinkedin = () => {
    window.open("https://www.linkedin.com/in/felipecalixto03/");
  }

  const navigateToGit = () => {
    window.open("https://github.com/Felipe-Calixto");
  }

  return (
    <div className={styles.conteiner}>
        <div className={styles.xmarkConteiner}>
          <span onClick={prop}><GiCancel/></span>
        </div>
        <div className={styles.infsConteiner}>
          <div className={styles.infs}>
            <p>Email: <a>felipecalixtoribas@gmail.com</a></p>
            <p>linkedin: <a href="https://www.linkedin.com/in/felipecalixto03/">felipecalixto03</a></p>
            <p>GitHub: <a href="https://github.com/Felipe-Calixto">Felipe-Calixto</a></p>
          </div>
          <div className={styles.iconsConteiner}>
            <span className={styles.icons} onClick={navigateToEmail}><CgMail/></span>
            <span className={styles.icons} onClick={navigateToLinkedin}><AiOutlineLinkedin/></span>
            <span className={styles.icons} onClick={navigateToGit}><AiFillGithub/></span>
          </div>
        </div>
    </div>
  )
}

export default Contact