import Contact from "./Contact";
import styles from "./ResponsiveNavBar.module.css"
import { useState } from "react";

const ResponsiveNavBar = () => {

    const [state, setState] = useState(false);


    const handleState = () => {
        setState(!state);
    }

    const cancelElement = () => {
        setState(false);
    }

    const handleScrollAbout = (event) => {
        event.preventDefault();
        const element = document.querySelector("#about");
        element.scrollIntoView({ behavior: "smooth" });
    }

    const handleScrollProjects = (event) => {
        event.preventDefault();
        const element = document.querySelector("#projects");
        element.scrollIntoView({ behavior: "smooth" });
    }


    return (
        <div className={styles.conteiner}>
            <span onClick={handleScrollAbout}>Sobre</span>
            <span onClick={handleScrollProjects}>Projetos</span>
            <span onClick={handleState}>Contato</span>
            {state && (
                <div className={styles.conteinerContact}>
                    <Contact prop={cancelElement} />
                </div>
            )}
        </div>
    )
}

export default ResponsiveNavBar