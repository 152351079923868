import './App.css';

//Components
import NavBar from './Components/NavBar';
import Introduction from './Components/Introduction';
import About from './Components/About';
import Projects from './Components/Projects';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Introduction/>
      <About/>
      <Projects/>
      <Footer/>
    </div>
  );
}

export default App;
